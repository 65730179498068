import imageCompression from "browser-image-compression";
import React, { useRef, useState } from "react";
import { TbTrash } from "react-icons/tb";
import { TailSpin } from "react-loader-spinner";
import { useTheme } from "../../../../context/ThemeContext";
import toast from "react-hot-toast";
import { UserService } from "../../../../../generated/services/UserService";
import { getUploadPath } from "../../../../utils/utils";
import { useAppContext } from "../../../../context/AppContext";
import { JournalService } from "../../../../../generated/services/JournalService";
import { ViewJournal } from "../Journal";

const ImagesComponent = ({
  screenShot,
  journalData,
  setScreenShots,
  fetchJournal,
}: {
  setScreenShots: (item: { path: string; imageSize: number }) => void;
  journalData: ViewJournal | undefined;
  screenShot: string[];
  fetchJournal: () => void;
}) => {
  const [isImageUploading, setIsImageUploading] = useState<boolean>(false);
  const { theme } = useTheme();
  const { userData } = useAppContext();
  const inputRef = useRef<HTMLInputElement>(null);

  async function fileChangeHandler(event: any) {
    setIsImageUploading(true);

    try {
      const file = event.target.files[0];

      if (file) {
        // Compress the image
        const compressedFile = await imageCompression(file, {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
        });

        // Convert the compressed file to Blob
        const fileBlob = new Blob([compressedFile], {
          type: compressedFile.type,
        });

        // Upload the file to the server
        const path = await UserService.userControllerUploadFile({
          file: fileBlob,
          path: getUploadPath(
            userData?._id,
            "journal",
            new Date().getTime().toString(),
            compressedFile.name
          ),
          contentType: compressedFile.type,
        });

        if (path.success) {
          const filePath = path.data.filePath.filePath;

          // Check if journal ID exists
          if (journalData?._id) {
            // Call the update API
            const upload =
              await JournalService.tradesJournalControllerUpdateJournal(
                journalData._id,
                { images: [filePath], imageSize: compressedFile.size }
              );
            if (upload.success) {
              fetchJournal();
            }
          } else {
            // Set the screenshot and let the parent handle the creation
            setScreenShots({
              path: filePath,
              imageSize: compressedFile.size,
            });
          }
        }
      }
    } catch (error: any) {
      toast.error("Failed to upload image");
      console.error("Error uploading image:", error);
    } finally {
      setIsImageUploading(false);
    }
  }

  async function handleDeleteScreenshot(index: number) {
    try {
      if (!journalData) {
        throw new Error("Journal data is undefined");
      }
      const response = await JournalService.tradesJournalControllerDeleteImage(
        journalData._id,
        { imageIndex: Number(index) }
      );

      if (response.success) {
        // setScreenShots(response.data.screenshots);
        fetchJournal();
      } else {
        throw new Error("Failed to delete screenshot");
      }
    } catch (error: any) {
      toast.error("Failed to delete screenshot");
    }
  }

  return (
    <div className="flex gap-4 h-full">
      <div className="flex justify-start items-center overflow-y-auto gap-2 w-full flex-wrap">
        {screenShot?.map((img, index) => (
          <div
            key={index}
            className="group w-[8rem] h-[8rem] relative overflow-hidden rounded-md shadow-lg flex items-center justify-center"
          >
            <TbTrash
              className="absolute top-2 right-2 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity duration-300"
              size={22}
              color="#ff5757"
              onClick={() => handleDeleteScreenshot(index)}
            />
            <a
              href={`${import.meta.env.VITE_S3_BUCKET_PUBLIC_URL}${img}`}
              target="_blank"
              className="w-full h-full"
            >
              <img
                src={`${import.meta.env.VITE_S3_BUCKET_PUBLIC_URL}${img}`}
                alt="screenshot"
                className="w-full h-full object-cover"
              />
            </a>
          </div>
        ))}
      </div>
      <div className="p-8 w-[8rem] h-[8rem] border-[2px] border-[#ADADAD] rounded-md border-dotted flex items-center justify-center">
        {isImageUploading ? (
          <TailSpin
            visible={true}
            height="25"
            width="25"
            color={theme === "light" ? "#36454F" : "black"}
            ariaLabel="tail-spin-loading"
            radius="1"
          />
        ) : (
          <>
            <label className="cursor-pointer text-[#ADADAD]" htmlFor="addimg">
              Add Image
            </label>
            <input
              type="file"
              id="addimg"
              className="hidden"
              ref={inputRef}
              onChange={fileChangeHandler}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ImagesComponent;
